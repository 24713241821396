import { generateFromObj } from '@bramus/pagination-sequence';
import { css } from 'astroturf';
import { graphql } from 'gatsby';
import React from 'react';
import PageHeader from '../components/PageHeader';
import PostListing from '../components/PostListing';
import SEO from '../components/SEO';
import Link from '../components/link';
import Layout from '../layout';

const styles = css`
  .pagination {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
    margin: 0 0 5rem;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;

    & li {
      aspect-ratio: 1/1;

      &:is(:first-child, :last-child) {
        aspect-ratio: initial;
        & a {
          aspect-ratio: initial;
        }
      }
    }

    & li :is(a, span) {
      margin: 0;
      display: block;
      border-radius: 4px;
      background: rgba(16, 125, 181, 0.15);
      box-shadow: none;
      line-height: 1;
      transition: 0.25s ease-in-out color;
      padding: 1rem;
      white-space: nowrap;

      @media (min-width: 40em) {
        padding: 1.5rem;
      }

      &.active {
        background: none;
        color: #000;
        line-height: 2;
        padding: 1rem 0.5rem;
      }

      &:hover {
        color: #000;
      }
    }
    & li span {
      aspect-ratio: auto;
      height: 100%;
      line-height: 2;
      padding: 1rem 0.5rem;
      background: none;
      color: #000;
    }
  }
`;

class Index extends React.Component {
  render() {
    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? '/blog/' : `/blog/${(currentPage - 1).toString()}/`;
    const nextPage = `/blog/${(currentPage + 1).toString()}/`;

    const postEdges = this.props.data.allMdx.edges;

    const arr = generateFromObj({
      curPage: currentPage,
      numPages,
    });

    return (
      <Layout>
        <SEO title="Blog" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1>Blog</h1>
          <h2>Tips for responsive design &amp; developer tools</h2>
          <p>
            Page {currentPage} of {numPages}
          </p>
        </PageHeader>
        <div className="index-container">
          <PostListing postEdges={postEdges} />
        </div>
        <nav aria-label="Blog">
          <ul className={styles.pagination}>
            {!isFirst && (
              <li>
                <Link to={prevPage} rel="prev">
                  ← Previous Page
                </Link>
              </li>
            )}
            {arr.map((_, i) => (
              <li>
                {_ === '…' ? (
                  <span>{_}</span>
                ) : (
                  <Link
                    key={`pagination-number${i + 1}`}
                    to={`/blog/${i === 0 ? '' : `${i + 1}/`}`}
                    activeClassName={styles.active}
                  >
                    {_}
                  </Link>
                )}
              </li>
            ))}
            {!isLast && (
              <li>
                <Link to={nextPage} rel="next">
                  Next Page →
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { fields: { collection: { eq: "posts" } } }
      limit: $limit
      skip: $skip
      sort: { fields: [fields___updated, fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
            updated
            collection
          }
          excerpt(pruneLength: 220)
          timeToRead
          frontmatter {
            title
            cover
            date
            updated
          }
        }
      }
    }
  }
`;
